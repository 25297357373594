//import cp from "@/plugins/cinepay";
import cp from "@/plugins/cinepay";
import emails from "@/plugins/emailApi";
import feda from "@/plugins/fedaPay";
import { lgcash, lgcashcg, lgcashgn } from "@/plugins/ligdi";
import axios from "axios";
import LmAPi from "@/plugins/LmApi"
import coinPay from "@/plugins/nowapi";

export default {



    getTrans() { },

    simpleTrans(context, payload) {
        return new Promise((resolve, reject) => {
            context,

                LmAPi({
                    method: 'POST',
                    url: '/transactions',
                    data: payload,
                    headers: { 'accept': 'application/json' },
                    responseType: 'json'

                }).then(
                    (r) => {
                        resolve(r);


                    }
                ).catch(
                    (e) => {
                        reject(e),
                            console.log(e)
                    }
                )


        })
    },
    cpPass1(context, payload) {
        let trans = Math.floor(Math.random() * 100000000).toString();
        console.log(trans);
        cp({
            method: 'POST',

            data: {
                "apikey": "117415196384bb1b545b30.73063196",
                "site_id": "892870",
                "transaction_id": trans,
                "amount": payload.detSimple.paid,
                "currency": "XOF",
                "description": payload.detSimple.type + ' ' + payload.detSimple.currency,

                //"notify_url": "https://www.nbbcserve.com/notifyCinepay",

                "channels": "ALL",
                "lang": "FR",
            },
            headers: { 'accept': 'application/json' },
            responseType: 'json'
        }).then((res) => {
            context.dispatch('TransactionValidPart1', {
                url: '/transactions/' + payload.id,
                data: {
                    "tnxid": trans,
                    "status": "paiement en cours",


                }
            });
            // console.log(res);
            //    context.commit('setUrl', r.data.data.payment_url)
            setTimeout(() => {
                // window.location.href = res['response_text'];
                window.open(res.data.data.payment_url, '_blank');
            }, 100);

            // window.location.href = res.data.data.payment_url;







            emails({
                method: 'POST',
                url: '/ts1',
                data: {
                    "id": payload.id,
                    "username": payload.user.username,
                    "currency": payload.detSimple.currency,
                    "adresse": payload.detSimple.adresse,
                    "paid": payload.detSimple.paid,
                    "received": payload.detSimple.received,
                    "receives": payload.detSimple.receiveM,
                    "url": res.data.data.payment_url
                },
                headers: {
                    'accept': 'application/json',

                }
            }).then((r) => { console.log(r) })
                .catch((e) => { console.log(e) })

        }

        ).catch((e) => {
            console.log(e);
        })

        /*
      
              lgcash({
                  method: 'POST',
      
                  data: {
                      "apikey": "117415196384bb1b545b30.73063196",
                      "site_id": "892870",
                      "transaction_id": trans,
                      "amount": payload.detSimple.paid,
                      "currency": "XOF",
                      "description": payload.detSimple.type + ' ' + payload.detSimple.currency,
      
                      //"notify_url": "https://www.nbbcserve.com/notifyCinepay",
      
                      "channels": "ALL",
                      "lang": "FR",
                  },
                  headers: { 'accept': 'application/json' },
                  responseType: 'json'
              }).then((res) => {
                  context.dispatch('TransactionValidPart1', {
                      url: '/transactions/' + payload.id,
                      data: {
                          "tnxid": trans,
                          "status": "paiement en cours",
      
      
                      }
                  });
                  console.log(res);
                  //    context.commit('setUrl', r.data.data.payment_url)
                  window.open(res.data.data.payment_url, '_blank');
      
      
      
      
      
      
      
                  emails({
                      method: 'POST',
                      url: '/ts1',
                      data: {
                          "id": payload.id,
                          "username": payload.user.username,
                          "currency": payload.detSimple.currency,
                          "adresse": payload.detSimple.adresse,
                          "paid": payload.detSimple.paid,
                          "received": payload.detSimple.received,
                          "receives": payload.detSimple.receiveM,
                          "url": res.data.data.payment_url
                      },
                      headers: {
                          'accept': 'application/json',
      
                      }
                  }).then((r) => { console.log(r) })
                      .catch((e) => { console.log(e) })
      
              }
      
              ).catch((e) => {
                  console.log(e);
              })
      
              /*
            
                    lgcash({
                        method: 'POST',
            
                        data: {
                            "commande": {
                                "invoice": {
                                    "items": [
                                        {
                                            "name": "Simple transaction",
                                            "description": payload.detSimple.type + ' ' + payload.detSimple.currency,
                                            "quantity": 1,
                                            "unit_price": payload.detSimple.paid,
                                            "total_price": payload.detSimple.paid
                                        }
                                    ],
                                    "total_amount": payload.detSimple.paid,
                                    "devise": "XOF",
                                    "description": payload.detSimple.type + ' ' + payload.detSimple.currency,
                                    "customer": "",
                                    "customer_firstname": payload.username,
                                    "customer_lastname": payload.username,
                                    "customer_email": payload.email
                                },
                                "store": {
                                    "name": "LMC",
                                    "website_url": "lumiercrypto.com"
                                },
                                "actions": {
                                    "cancel_url": "https://webhook.site/a1d3f145-9121-4881-9185-93484b65d510",
                                    "return_url": "https://webhook.site/a1d3f145-9121-4881-9185-93484b65d510",
                                    "callback_url": "https://webhook.site/a1d3f145-9121-4881-9185-93484b65d510"
                                },
                                "custom_data": {
                                    "transaction_id": payload.id
                                }
                            }
                        }
                        ,
            
                        responseType: 'json'
                    }).then((r) => { console.log(r, payload) })
                        .catch((e) => { console.log(e) })*/
    },
    TransactionValidPart1(context, payload) {
        context,
            LmAPi({
                method: 'PUT',
                url: payload.url,
                data: payload.data,
                headers: { 'accept': 'application/json' },
                responseType: 'json'

            }).then((response) => { console.log(response) })
                .catch((e) => { console.log(e) })

    },
    cpPass2(context, payload) {
        context,
            coinPay({
                method: 'POST',
                url: '/pay2',
                data: payload.data,
                headers: { 'accept': 'application/json' },
                responseType: 'json'
            }).then((response) => {
                var data = response.data.pay.result;
                context.dispatch('TransactionValidPart1', {
                    url: '/transactions/' + payload.id,
                    data: {
                        "tnxid": data.txn_id,
                        "status": "paiement en cours",


                    }
                });
                //   console.log(data.txn_id);
                //    context.commit('setUrl', r.data.data.payment_url)
                setTimeout(() => {
                    // window.location.href = res['response_text'];
                    window.location.href = data.checkout_url;
                }, 100);
                //   window.location.href = data.checkout_url;
                // window.open(data.checkout_url, '_blank');

                emails({
                    method: 'POST',
                    url: '/ts1',
                    data: {
                        "id": payload.mail.id,
                        "username": payload.mail.user.username,
                        "currency": payload.mail.detSimple.currency,
                        "adresse": payload.mail.detSimple.adresse,
                        "paid": payload.mail.detSimple.paid,
                        "received": payload.mail.detSimple.received,
                        "receives": payload.mail.detSimple.receiveM,
                        "url": data.checkout_url
                    },
                    headers: {
                        'accept': 'application/json',

                    }
                }).then((r) => { console.log(r) })
                    .catch((e) => { console.log(e) })

            })
                .catch((e) => { console.log(e) })


    },

    cpPass3(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post('https://api.nowpayments.io/v1/invoice',
                payload.data,
                {
                    headers: {
                        'x-api-key': "6WGTHRG-WKV4J4R-JHGD8X2-GWQSD56",
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(response => {
                    console.log(response.data); // Log pour le débogage
                    resolve(response.data); // Résoudre la promesse avec les données de la réponse
                })
                .catch(error => {
                    const errorData = error.response ? error.response.data : error.message;
                    console.error("Error:", errorData); // Log des erreurs
                    reject(errorData); // Rejeter la promesse avec les données de l'erreur
                });
        });
    }
    ,
    ligdi(context, payload) {
        context,
            lgcash({
                method: 'POST',

                data: {
                    "commande": {
                        "invoice": {
                            "items": [
                                {
                                    "name": "Exchange Pro",
                                    "description": payload.detPro.giveM + ' <=> ' + payload.detPro.receiveM,
                                    "quantity": 1,
                                    "unit_price": payload.detPro.give,
                                    "total_price": payload.detPro.give
                                }
                            ],
                            "total_amount": payload.detPro.give,
                            "devise": "XOF",
                            "description": payload.detPro.giveM + ' <=> ' + payload.detPro.receiveM,
                            "customer": "",
                            "customer_firstname": payload.user.username,
                            "customer_lastname": payload.user.username,
                            "customer_email": payload.email
                        },
                        "store": {
                            "name": "LMC",
                            "website_url": "lumiercrypto.com"
                        },
                        "actions": {
                            "cancel_url": "https://www.lmcserve.com/ipn/ligdi",
                            "return_url": "https://www.lmcserve.com/ipn/ligdi",
                            "callback_url": "https://www.lmcserve.com/ipn/ligdi"
                        },
                        "custom_data": {
                            "transaction_id": payload.id
                        }
                    }
                }
                ,

                responseType: 'json'
            }).then((r) => {

                let res = r.data;
                context.dispatch('TransactionValidPart1', {
                    url: '/transactions/' + payload.id,
                    data: {
                        "tnxid": res.token,
                        "status": "paiement en cours",
                        "detPro": {
                            "giveM": payload.detPro.giveM,
                            "give": payload.detPro.give,
                            "receiveM": payload.detPro.receiveM,
                            "receive": payload.detPro.receive,
                            "paidM": payload.paidM,
                            "txid": "0",
                            "adresse": payload.adresse
                        }


                    }
                });

                //    context.commit('setUrl', r.data.data.payment_url)
                //
                setTimeout(() => {
                    window.location.href = res['response_text'];
                }, 100);
                //   window.open(res['response_text'], '_blank');


                // console.log(r, payload) 




            })
                .catch((e) => { console.log(e) })
    },
    ligdi2(context, payload) {
        context,
            lgcashcg({
                method: 'POST',

                data: {
                    "commande": {
                        "invoice": {
                            "items": [
                                {
                                    "name": "Exchange Pro",
                                    "description": payload.detPro.giveM + ' <=> ' + payload.detPro.receiveM,
                                    "quantity": 1,
                                    "unit_price": payload.detPro.give,
                                    "total_price": payload.detPro.give
                                }
                            ],
                            "total_amount": payload.detPro.give,
                            "devise": "XOF",
                            "description": payload.detPro.giveM + ' <=> ' + payload.detPro.receiveM,
                            "customer": "",
                            "customer_firstname": payload.user.username,
                            "customer_lastname": payload.user.username,
                            "customer_email": payload.email
                        },
                        "store": {
                            "name": "LMC",
                            "website_url": "lumiercrypto.com"
                        },
                        "actions": {
                            "cancel_url": "https://www.lmcserve.com/ipn/ligdi2",
                            "return_url": "https://www.lmcserve.com/ipn/ligdi2",
                            "callback_url": "https://www.lmcserve.com/ipn/ligdi2"
                        },
                        "custom_data": {
                            "transaction_id": payload.id
                        }
                    }
                }
                ,

                responseType: 'json'
            }).then((r) => {

                let res = r.data;
                context.dispatch('TransactionValidPart1', {
                    url: '/transactions/' + payload.id,
                    data: {
                        "tnxid": res.token,
                        "status": "paiement en cours",
                        "detPro": {
                            "giveM": payload.detPro.giveM,
                            "give": payload.detPro.give,
                            "receiveM": payload.detPro.receiveM,
                            "receive": payload.detPro.receive,
                            "paidM": payload.paidM,
                            "txid": "0",
                            "adresse": payload.adresse
                        }


                    }
                });

                //    context.commit('setUrl', r.data.data.payment_url)
                //
                setTimeout(() => {
                    window.location.href = res['response_text'];
                }, 100);
                //   window.open(res['response_text'], '_blank');


                // console.log(r, payload) 




            })
                .catch((e) => { console.log(e) })
    }, ligdi3(context, payload) {
        context,
            lgcashgn({
                method: 'POST',

                data: {
                    "commande": {
                        "invoice": {
                            "items": [
                                {
                                    "name": "Exchange Pro",
                                    "description": payload.detPro.giveM + ' <=> ' + payload.detPro.receiveM,
                                    "quantity": 1,
                                    "unit_price": payload.detPro.give,
                                    "total_price": payload.detPro.give
                                }
                            ],
                            "total_amount": payload.detPro.give,
                            "devise": "XOF",
                            "description": payload.detPro.giveM + ' <=> ' + payload.detPro.receiveM,
                            "customer": "",
                            "customer_firstname": payload.user.username,
                            "customer_lastname": payload.user.username,
                            "customer_email": payload.email
                        },
                        "store": {
                            "name": "LMC",
                            "website_url": "lumiercrypto.com"
                        },
                        "actions": {
                            "cancel_url": "https://www.lmcserve.com/ipn/ligdi3",
                            "return_url": "https://www.lmcserve.com/ipn/ligdi3",
                            "callback_url": "https://www.lmcserve.com/ipn/ligdi3"
                        },
                        "custom_data": {
                            "transaction_id": payload.id
                        }
                    }
                }
                ,

                responseType: 'json'
            }).then((r) => {

                let res = r.data;
                context.dispatch('TransactionValidPart1', {
                    url: '/transactions/' + payload.id,
                    data: {
                        "tnxid": res.token,
                        "status": "paiement en cours",
                        "detPro": {
                            "giveM": payload.detPro.giveM,
                            "give": payload.detPro.give,
                            "receiveM": payload.detPro.receiveM,
                            "receive": payload.detPro.receive,
                            "paidM": payload.paidM,
                            "txid": "0",
                            "adresse": payload.adresse
                        }


                    }
                });

                //    context.commit('setUrl', r.data.data.payment_url)
                //
                setTimeout(() => {
                    window.location.href = res['response_text'];
                }, 100);
                //   window.open(res['response_text'], '_blank');


                // console.log(r, payload) 




            })
                .catch((e) => { console.log(e) })
    }, async fedapay(context, payload) {
        try {
            const response = await feda({
                method: 'POST',
                data: {
                    description: 'Transaction for ' + payload.user.username,
                    amount: payload.detPro.give,
                    currency: { iso: 'XOF' },
                    callback_url: 'https://lmcserve.com/ipn/fedapay',
                    customer: {
                        email: payload.user.email,
                    }
                }
            });

            let res = response.data;
            const fedares = res["v1/transaction"];

            // Dispatch de l'action avec les détails de la transaction
            await context.dispatch('TransactionValidPart1', {
                url: '/transactions/' + payload.id,
                data: {
                    tnxid: fedares.id.toString(),
                    status: "paiement en cours",
                    detPro: {
                        giveM: payload.detPro.giveM,
                        give: payload.detPro.give,
                        receiveM: payload.detPro.receiveM,
                        receive: payload.detPro.receive,
                        paidM: payload.paidM,
                        txid: "0",
                        adresse: payload.adresse
                    }
                }
            });

            // Retourne la transaction pour utilisation ultérieure
            return fedares;

        } catch (error) {
            console.error(error);
            throw error; // Pour propager l'erreur si besoin
        }
    }

}

